import React from 'react';
import { Route } from 'react-router-dom';
import RegisterPage from './Pages/Register/RegisterPage'
import PreLaunchDashboard from './Pages/Dashboard/PreLaunchDashboard'
import Landing from "./Pages/Landing/Landing";
import Privacy from './Pages/Landing/Privacy';
import Terms from './Pages/Landing/Terms';
import ComingSoon from './Pages/Dashboard/ComingSoon';
import MoreVideos from './Pages/Dashboard/MoreVideos';
import Photos from './Pages/Dashboard/Photos';


export default [
  <Route exact path={'/register'} name="RegisterPage" component={RegisterPage} noLayout/>,
  <Route exact path={['/privacy']} name="Privacy" noLayout component={Privacy}/>,
  <Route exact path={['/terms']} name="Terms" noLayout component={Terms}/>,
  <Route exact path={['/', '/home']} name="LandingPage" noLayout component={Landing}/>,
  <Route exact path={['/', "/dashboard"]} name="Dashboard" component={PreLaunchDashboard}/>,
  <Route exact path={['/sexyvideos']} name="More Videos" component={MoreVideos}/>,
  <Route exact path={['/photos']} name="Sexy Photos" component={Photos}/>,
  <Route exact path={['/wardrobe']} name="Wardrobe" component={ComingSoon}/>,

];
