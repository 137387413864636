import React from "react";
import { Link } from "react-router-dom";
import { Box, Flex, Text, Menu, MenuButton, MenuList, MenuItem, useColorMode, IconButton } from "@chakra-ui/react";
import { SettingsIcon } from '@chakra-ui/icons'
import Logo from "../ui/Logo";

const Settings = ({isLast, ...rest}) => {
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    <Menu>
      <IconButton as={MenuButton} icon={<SettingsIcon />} />
      <MenuList>
        <MenuItem onClick={toggleColorMode}>Toggle {colorMode === "light" ? "Dark" : "Light"}</MenuItem>
      </MenuList>
    </Menu>
  )
}

const CutomMenuItem = ({ children, isLast, to = "/", ...rest }) => {
  return (
    <Text
      mb={{ base: isLast ? 0 : 8, sm: 0 }}
      mr={{ base: 0, sm: isLast ? 0 : 8 }}
      display="block"
      {...rest}
    >
      <Link to={to}>{children}</Link>
    </Text>
  );
};

const CutomLinkItem = ({ children, isLast, to = "/", ...rest }) => {
  return (
    <Text
      mb={{ base: isLast ? 0 : 8, sm: 0 }}
      mr={{ base: 0, sm: isLast ? 0 : 8 }}
      display="block"
      {...rest}
    >
      <a target="_blank" rel="noopener noreferrer" href={to}>{children}</a>
    </Text>
  );
};


const Footer = (props) => {
  const [show, setShow] = React.useState(false);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      p={8}
      bg={["primary.500", "primary.500", "transparent", "transparent"]}
      color={["white", "white", "primary.700", "primary.700"]}
      {...props}
    >
      <Flex align="center">
        <Logo
          w="200px"
          color={["white", "white", "primary.500", "primary.500"]}
        />
        <Text color={["white", "primary.900"]}>Copyright © 2021. All rights reserved</Text><br/>
      </Flex>


      <Box
        display={{ base: show ? "block" : "block", md: "block", sm: 'block' }}
        flexBasis={{ base: "100%", md: "auto", sm: 'auto' }}
      >
        <Flex
          align="center"
          justify={["center", "space-between", "flex-end", "flex-end"]}
          direction={["column", "row", "row", "row"]}
          pt={[4, 4, 0, 0]}
          color={["white", "primary.900"]}
        >
          <CutomMenuItem to="/privacy">Privacy</CutomMenuItem>
          <CutomMenuItem to="/terms">Terms & Conditions</CutomMenuItem>
          <CutomLinkItem to="https://docs.google.com/forms/d/e/1FAIpQLSfVa65iGew9Oma86FM00iNLS1JRp6gouGq_kacTdMpos605Wg/viewform?usp=sf_link">Contact Me</CutomLinkItem>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Footer;
