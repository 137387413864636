import React from "react";

import Hero from "../../components/sections/Hero";
import LandingLayout from "../../components/layouts/LandingLayout";
import { Box, Heading, Text, Stack, Button } from "@chakra-ui/react";
import { ListItem, UnorderedList } from "@chakra-ui/react"
import { Link } from "react-router-dom";


export default function Landing() {
  return (
    <LandingLayout>
      <Hero
        title="Alexa Bree"
        subtitle="THE GIRLY QUEEN B."
        image="/img/main-background.jpg"
        ctaText="GET INSTANT ACCESS!"
        ctaLink="https://www.patreon.com/alexabree"
      />

      <Box id="about" px={8} mb={16} >
        <Stack spacing={6} align='center'>
          <Heading as='h2' size="xl" align='center'>I’m Alexa Bree and welcome to my private group for my fans.</Heading>
          <Heading as='h2' size="lg" align='center'>As you know from my YouTube channel, I love to dress up & down ;) in all feminine things from pantyhose, stockings, skirts, dresses, lingerie, heels, makeup and love sharing it with the world on YouTube.</Heading>
          <Heading as='h2' size="lg" align='center'>Some of my videos can be found on YouTube, but I wanted create a place where people can watch MORE of my never before seen videos at FULL length - a place where you can support me and what I do.</Heading>
          <Heading as='h2' size="lg" align='center'>{"In my private group you'll experience my unique POV style videos and get access to:"}</Heading>
          <UnorderedList spacing={3} as='heading' fontSize='lg' fontWeight='500'>
            <ListItem>Instant access of my <Text fontWeight='bold' d='inline'>never before seen footage</Text></ListItem>
            <ListItem><Text fontWeight='bold' d='inline'>Live streams</Text> for Private fans only</ListItem>
            <ListItem><Text fontWeight='bold' d='inline'>1-1 chats</Text> with Alexa</ListItem>
            <ListItem><Text fontWeight='bold' d='inline'>Sexy photo shoots</Text> of me dressed up / getting ready</ListItem>
            <ListItem><Text fontWeight='bold' d='inline'>Access to my wardrobe & Girlfriends wardrobe</Text> - Purchases available on request</ListItem>
            <ListItem><Text fontWeight='bold' d='inline'>Custom made video made by me</Text> - specifically for you! - I play out your ideal fantasy</ListItem>
            <ListItem><Text fontWeight='bold' d='inline'>Private Date - Video Chat With Me</Text> - Take me on a DATE! Just the two of us ;) All over LIVE video chat. Choose what I wear (lingerie, heels, dress, makeup), I get all dolled up just for you !!</ListItem>
          </UnorderedList>

          <a target="_blank" href="https://www.patreon.com/alexabree">
            <Button colorScheme="primary" borderRadius="8px" py="4" px="8" lineHeight="1" size="lg" >
            GET INSTANT ACCESS!
            </Button>
          </a>
        </Stack>
      </Box>

    </LandingLayout>
  );
}
