import { observable, decorate } from 'mobx';
import FirebaseStore from './FirebaseStore';

class ProfileStore {
  // @observable
  currentUser;

  bchChartData_1d = null;
  bchChartData_7d = null;

  constructor() {
    // console.log("ProfileStore");
    this.authListener();
  }

  authListener() {
    FirebaseStore.getFirebaseApp().auth().onAuthStateChanged((user) => {
      if (user) {
        this.setCurrentUser(user);
      } else {
        this.setCurrentUser(null);
      }
    });
  }

  setCurrentUser(user) {
    this.currentUser = user;
  }

  async logout() {
    try {
      await FirebaseStore.getFirebaseApp().auth().signOut();
      // signed out
    } catch (e){
     // an error
    }
  }

  getUserObj() {
    if (this.currentUser) {
      let userObj = {
        uid: this.currentUser.uid,
        displayName: this.currentUser.displayName,
        email: this.currentUser.email,
        photoURL: this.currentUser.photoURL,
      };

      return userObj;
    } else {
      return null;
    }
  }

  async hasRequstedOnBoarding() {
    const userObj = this.getUserObj();
    if (userObj) {
      const db = FirebaseStore.getFirebaseApp().firestore();
      let data = await db.collection('Users').doc(userObj.uid).get()
      let result = data.data();

      if (result && result.isOnBoarded) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async saveOnBoardingForm(onboardingObj) {
    const userObj = this.getUserObj();
    if (userObj) {
      const db = FirebaseStore.getFirebaseApp().firestore();
      await db.collection('Users').doc(userObj.uid).set(onboardingObj)

      return true;
    } else {
      return false;
    }
  }

  async getPaymentStatus() {
    const userObj = this.getUserObj();
    if (userObj) {
      const db = FirebaseStore.getFirebaseApp().firestore();
      await db.collection('Users').doc(userObj.uid).get();

      return true;
    } else {
      return false;
    }
  }

  async savePaymentStatus(paymentStatusObj) {
    const userObj = this.getUserObj();
    if (userObj) {
      const db = FirebaseStore.getFirebaseApp().firestore();
      await db.collection('Users').doc(userObj.uid).set(paymentStatusObj)

      return true;
    } else {
      return false;
    }
  }

  async hasUserPaied() {
    const userObj = this.getUserObj();
    if (userObj) {
      const db = FirebaseStore.getFirebaseApp().firestore();
      let data = await db.collection('Users').doc(userObj.uid).get()
      let result = data.data();

      if (result && result.paymentStatus === 'success') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async createNewUser(newUserObj) {
      try {
        const createdUser = await FirebaseStore.getFirebaseApp().auth().createUserWithEmailAndPassword(newUserObj.email, newUserObj.password);
        await createdUser.user.sendEmailVerification({
          url: 'https://Recycle.st',
        });
        await createdUser.user
          .updateProfile({
            displayName: newUserObj.email,
            phoneNumber: '041393233',
            photoURL: `http://gravatar.com/avatar/1?d=identicon`,
          });

        const db = FirebaseStore.getFirebaseApp().firestore();
        const newOnBoarding = await db.collection('Users').doc(createdUser.user.uid).set({
          isOnBoarded: false,
          address: '',
          phone: '',
          formattedAddress: '',
          addressComponents: ''
        });

        return newOnBoarding;
      } catch(e) {
        return e;
      }
  }

}

decorate(ProfileStore, {
    currentUser: observable
});

export default new ProfileStore();
