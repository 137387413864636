import React, {useState, useEffect, useRef} from 'react';
import { observer, inject } from 'mobx-react';
import { Title, useNotify } from 'react-admin';
import { Box, Text, SimpleGrid , Heading, Stack, Button, UnorderedList, ListItem } from "@chakra-ui/react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import VideoView from './VideoView';

// import StripeScriptLoader from 'react-stripe-script-loader'
// import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from '../Payment/CheckoutForm';

const stripePromise = loadStripe("pk_live_51IFoaSEIJnQeTueO4UDmB2fyRPNpJSL9Wd7DQIIqObDMvEA1uKDkGMBVdDldIj4spV6YlSOHp2JhSNooRZDl1JwH00vjFbOTvW");


const Dashboard = (props) => {
    const [payWithCard, setPayWithCard] = useState(false);
    const [tierSelected, setTierSelected] = useState('PREMIUM');
    const [tierPrice, setTierPrice] = useState('$20');
    const [hasPaid, setHasPaid] = useState(false);
    const paymentRef = useRef(null)

    function payWithCardClicked(tier, price) {
      setPayWithCard(!payWithCard);
      setTierSelected(tier)
      setTierPrice(price)
      notify(tier + " Membership Selected!", "info");
      setTimeout(()=> {
        paymentRef.current.scrollIntoView();
      }, 500);
    }
    const notify = useNotify();

    useEffect( () => {
      (async () => {
        const isPaid = await props.ProfileStore.hasUserPaied();
        setHasPaid(isPaid)
      })();
    }, [props.ProfileStore]);

    return (
      <SimpleGrid minChildWidth="15px" columns={2} spacing={4} m='3%'>
        <Title title={''} />

        {hasPaid ?
          <Stack direction={["column"]} spacing="8px">
            <VideoView title='Welcome xx - Dressed as a Maid' videoSrc="https://www.youtube.com/embed/O9cmc0h5Hvc?controls=0&rel=0&disablekb=1&showinfo=0&wmode=transparent&showinfo=0" />
            <VideoView title='Cute thigh high stockings & mini skirt' videoSrc="https://www.youtube.com/embed/8aj3B595_Dw?controls=0&rel=0&disablekb=1&showinfo=0&wmode=transparent&showinfo=0" />
            <VideoView title='Dressed as a sexy sailor' videoSrc="https://www.youtube.com/embed/JhYjD-NyQSA?controls=0&rel=0&disablekb=1&showinfo=0&wmode=transparent&showinfo=0" />
          </Stack>
        :
          <Stack direction={["column"]} spacing="8px">

          <Heading align='center'>Select a membership level</Heading>
          <Stack direction={["column", "row"]} spacing="2px">
            <Box>
                <Button onClick={()=>payWithCardClicked('PREMIUM', '$20')} m={2} bg="green.200">PREMIUM - $20</Button>
                <UnorderedList spacing={3} as='heading' fontSize='lg' fontWeight='500'>
                  <ListItem>Instant access of my <Text fontWeight='bold' d='inline'>never before seen footage</Text></ListItem>
                  <ListItem><Text fontWeight='bold' d='inline'>Live streams</Text> for Private fans only</ListItem>
                  <ListItem><Text fontWeight='bold' d='inline'>1-1 chats</Text> with Alexa</ListItem>
                  <ListItem><Text fontWeight='bold' d='inline'>Sexy photo shoots</Text> of me dressed up / getting ready</ListItem>
                  <ListItem><Text fontWeight='bold' d='inline'>Access to my wardrobe & Girlfriends wardrobe</Text> - Purchases available on request</ListItem>
                </UnorderedList>
              </Box>
              <Box>
                <Button onClick={()=>payWithCardClicked("CUSTOM VIDEO", '$65')} m={2} bg="purple.200">CUSTOM VIDEO  - $65</Button>
                <UnorderedList spacing={3} as='heading' fontSize='lg' fontWeight='500'>
                  <ListItem><Text fontWeight='bold' d='inline'>Custom made video made by me</Text> - specifically for you! - I play out your ideal fantasy</ListItem>
                  <ListItem><Text fontWeight='bold' d='inline'>PLUS Everything in premium level</Text></ListItem>
                </UnorderedList>
              </Box>
              <Box>
                <Button onClick={()=>payWithCardClicked("PRIVATE DATE", '$120')} m={2} bg="pink.200">PRIVATE DATE - $120</Button>
                <UnorderedList spacing={3} as='heading' fontSize='lg' fontWeight='500'>
                  <ListItem><Text fontWeight='bold' d='inline'>Private Date - Video Chat With Me</Text> - Take me on a DATE! Just the two of us ;) All over LIVE video chat. Choose what I wear (lingerie, heels, dress, makeup), I get all dolled up just for you !!</ListItem>
                  <ListItem><Text fontWeight='bold' d='inline'>PLUS Everything in premium level</Text></ListItem>
                </UnorderedList>
              </Box>

          </Stack>
          <hr />

          <Box w="25em" m={0} ref={paymentRef}>
            <Elements stripe={stripePromise}>
              <CheckoutForm tierSelected={tierSelected} tierPrice={tierPrice}/>
            </Elements>
          </Box>
        </Stack>

      }
      </SimpleGrid>
  );

}

export default inject("ProfileStore")(observer(Dashboard))
