import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ListIcon from '@material-ui/icons/List';
import HomeIcon from '@material-ui/icons/Home';
import { observer, inject } from 'mobx-react';
import { Avatar, AvatarBadge, Container, Text } from "@chakra-ui/react"
import '../App.css'


const Menu = ({ onMenuClick, dense, logout, ProfileStore }) => {
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const [hasPaid, setHasPaid] = useState(false);
    const userObj = ProfileStore.getUserObj();

    useEffect( () => {
      (async () => {
        const isPaid = await ProfileStore.hasUserPaied();
        setHasPaid(isPaid)
      })();
    }, [ProfileStore]);

    return (
        <div style={{backgroundColor: '#3196f3', height: '100vh', textAlign: 'center'}}>
        <Container padding={2}>
          <Avatar src="/img/default_profile_pic.svg" size="lg">
            <AvatarBadge boxSize="1em" bg="green.500" borderColor="green.400"/>
          </Avatar>
          <Text size="sm" isTruncated padding={2}>{(userObj != null ? userObj.email : null)}</Text>
        </Container>
          <MenuItemLink
              to="/"
              primaryText="Home"
              leftIcon={<HomeIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
          />
          <MenuItemLink
              to="/dashboard"
              primaryText="Dashboard"
              leftIcon={<DashboardIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
          />
          {hasPaid ?
            <span>
            <MenuItemLink
              to="/sexyvideos"
              primaryText="More Videos"
              leftIcon={<ListIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
          />
          <MenuItemLink
              to="/photos"
              primaryText="Sexy Photos"
              leftIcon={<ListIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
          />
          <MenuItemLink
              to="/wardrobe"
              primaryText="Wardrobe"
              leftIcon={<DefaultIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
          />
          </span>
          : null}
          {/*resources.map(resource => (
              (ShouldHideMenu(resource.name) ? <MenuItemLink
                  key={resource.name}
                  to={`/${resource.name}`}
                  primaryText={
                      (resource.options && resource.options.label) ||
                      capitalize(resource.name)
                  }
                  leftIcon={
                      resource.icon ? <resource.icon /> : <DefaultIcon />
                  }
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
              /> : null)
          ))*/}

          {logout}
        </div>
    );
};

export default inject("ProfileStore")(observer(Menu))
