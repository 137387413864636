import React, { useState} from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import { observer, inject } from 'mobx-react';
import { Button, Text, Heading, FormControl, FormErrorMessage, FormHelperText } from "@chakra-ui/react"
import { LockIcon } from '@chakra-ui/icons'


// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
  return {
    options: {
      hidePostalCode: true,
    },
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};

const CheckoutForm = (props) => {

  const [paymentComplete, setPaymentComplete] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const userObj = props.ProfileStore.getUserObj();
  const stripe = useStripe();
  const elements = useElements();

  const handleChange = ({error}) => {
    if (error) {
      setErrorMessage(error.message);
    }
  };

  const handleSubmitSubscription = async (evt) => {
    evt.preventDefault();
    setPaymentLoading(true);
    const email = userObj.email;

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: userObj.email,
      },
    });

    if (result.error) {
      setErrorMessage(result.error.message);
    } else {
      let res = await fetch("https://us-central1-ab-fans-site.cloudfunctions.net/api/ChargeSubscription", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({'payment_method': result.paymentMethod.id, 'email': email, tierSelected: props.tierSelected})
      });

      let responseJson = await res.json();

      if (responseJson.statusCode > 299) {
        setErrorMessage(responseJson.raw.message);
        setPaymentLoading(false);
        setPaymentComplete(false);
        return;
      }

      const {client_secret, status} = responseJson;

      if (!status || status === 'requires_action') {
        stripe.confirmCardPayment(client_secret).then((result) => {
          if (result.error) {
            setErrorMessage(result.error.message);
            savePaymentStatus({
              paymentStatus: 'failed'
            })
          } else {
            setPaymentLoading(false);
            setPaymentComplete(true);
            savePaymentStatus({
              paymentStatus: 'success'
            })
          }
        });
      } else {
        setPaymentLoading(false);
        setPaymentComplete(true);
        savePaymentStatus({
          paymentStatus: 'success'
        })
      }
    }
  };

  const savePaymentStatus = async (statusObj) => {
    props.ProfileStore.savePaymentStatus(statusObj)
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setPaymentLoading(true);

    await sendPaymentRequest();

    // setErrorMessage(error.message);
    // setPaymentLoading(false);
  };

  const sendPaymentRequest = async () => {
    const email = userObj.email;

    let res = await fetch("https://us-central1-ab-fans-site.cloudfunctions.net/api/charge", {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tier: props.tierSelected,
        email: email
      })
    });

    const {client_secret} = await res.json();
    const result = await stripe.confirmCardPayment(client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          email: email,
        },
      },
    });

    if (result.error) {
      setErrorMessage(result.error.message);
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        setPaymentLoading(false);
        setPaymentComplete(true);
      }
    }
  };


    return (
      <div className="CardDemo">
        {!paymentComplete ?
          <form onSubmit={handleSubmitSubscription}>
            <FormControl id="cardDetails" isInvalid={!paymentComplete}>
              <Heading size='xl' mt={10} align='center'>Pay via Stripe</Heading>
              <Text mb={8} align='center' fontWeight='bold'>Membership Selected: {props.tierSelected} tier - {props.tierPrice}</Text>
              <CardElement
                id='my-card'
                onChange={handleChange}
                {...createOptions()}
              />
              <FormHelperText style={{paddingBottom: '2em', paddingTop: '1em'}}>{"Powered and Secured by Stripe."}</FormHelperText>
              <FormErrorMessage>{errorMessage}</FormErrorMessage>
            </FormControl>
            {paymentComplete === false &&
              <Button mb={20} type="submit" rightIcon={<LockIcon />} bg='green.300' className="stripeButton" isLoading={paymentLoading} loadingText="Processing...">Pay</Button>
            }
          </form>
        : <Text fontWeight='bold' size='3xl'>Payment Received - Account setup in progress, check back again soon!</Text>
        }
      </div>
    );

}

export default inject("ProfileStore")(observer(CheckoutForm))
