import { observable, decorate } from 'mobx';
import FirebaseStore from './FirebaseStore';

class DataStore {
  // @observable
  currentUser;

  async uploadImage(imageFile) {
    const storageRef = FirebaseStore.getFirebaseApp().storage().ref('ArticleImages/' + imageFile.name);
    let result = await storageRef.put(imageFile)
    const imageUrl = await storageRef.getDownloadURL();
    // console.log(imageUrl)
    return imageUrl;
  }

  async saveFeedback(feedbackObj) {
    const db = FirebaseStore.getFirebaseApp().firestore();
    if (this.currentUser) {
      feedbackObj['currentUser'] = this.getUserObj()
    }
    db.collection("feedbackData").add({...feedbackObj})
    .then(function() {
        console.log("Document successfully written!");
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    });
  }

  async saveFeedbackCreatedForm(formObj) {
    const db = FirebaseStore.getFirebaseApp().firestore();

    db.collection("feedbackForms").doc(this.currentUser.uid).set({...formObj})
    .then(function() {
        console.log("Document successfully written!");
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    });
    // return result;
    // .then(function() {
    //     console.log("Document successfully written!");
    // })
    // .catch(function(error) {
    //     console.error("Error writing document: ", error);
    // });
  }


  async getArticleData(articleID) {
    const db = FirebaseStore.getFirebaseApp().firestore();
    return db.collection("articleData").doc(articleID).get().then((querySnapshot) => {
      return querySnapshot
        // querySnapshot.forEach((doc) => {
        //     console.log(`${doc.id} => ${doc.data()}`);
        //     console.log(doc.data())
        // });
    });
  }

  saveArticleData(articleData) {
    const db = FirebaseStore.getFirebaseApp().firestore();
    db.collection("articleData").doc(articleData.article_url_id).set({...articleData})
    .then(function() {
        console.log("saveArticleData Document successfully written!");
    })
    .catch(function(error) {
        console.error("saveArticleData Error writing document: ", error);
    });
  }

  async getDashboardArticles() {
    const db = FirebaseStore.getFirebaseApp().firestore();
    return db.collection("articleData").get().then((querySnapshot) => {
      return querySnapshot
        // querySnapshot.forEach((doc) => {
        //     console.log(`${doc.id} => ${doc.data()}`);
        //     console.log(doc.data())
        // });
    });
  }

  async getRejectedDealIds(currentUserEmail) {
    const db = FirebaseStore.getFirebaseApp().firestore();
    const qs = await db.collection("DealReject").where('rejectedBy', '==', currentUserEmail).get();
    // .get().then(querySnapshot => {
    //   querySnapshot.forEach(doc => {
    //     console.log(doc.data())
    //   })
    // });
    // console.log(querySnapshot)
    return qs
    // .get().then((querySnapshot) => {
    //   return querySnapshot
    //     // querySnapshot.forEach((doc) => {
    //     //     console.log(`${doc.id} => ${doc.data()}`);
    //     //     console.log(doc.data())
    //     // });
    // });
  }

  async getFormFromFromId(formID) {
    const db = FirebaseStore.getFirebaseApp().firestore();
    return db.collection("feedbackForms").doc(formID).get().then((querySnapshot) => {
      return querySnapshot
    });
  }
}

decorate(DataStore, {
    currentUser: observable
});

export default new DataStore();
