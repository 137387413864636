import React from 'react';
import { Layout, Sidebar } from 'react-admin';
import MyMenu from './Menu';
import Notifications from './Notifications';
import AppBar from './AppBar';
import { makeStyles } from '@material-ui/core/styles';

const useSidebarStyles = makeStyles({
    drawerPaper: {
        margin: 0,
        // width: 300
    },
});
const MySidebar = props => {
    const classes = useSidebarStyles();
    return (
        <Sidebar classes={classes} {...props} />
    );
};


const MyLayout = (props) => <Layout {...props} appBar={AppBar} menu={MyMenu} sidebar={MySidebar} notification={Notifications}/>;

export default MyLayout;
