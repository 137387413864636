import React from "react";

import LandingLayout from "../../components/layouts/LandingLayout";
import PrivacyContent from './PrivacyContent'

export default function Privacy() {
  return (
    <LandingLayout>
      <PrivacyContent />
    </LandingLayout>
  );
}
