import React, {useState} from 'react';
import { useLogin } from 'react-admin';
import firebase from 'firebase';
import ForgotPassword from './ForgotPassword';
import Avatar from '@material-ui/core/Avatar';
import { CircularProgress, Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';


function ButtonComponent(props) {
  const { disabled } = props;
  return (
    <Button {...props} disabled={disabled}>
      {disabled && <CircularProgress size={14} />}
      {!disabled && 'Sign In'}
    </Button>
  );
}


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://AlexaBree.com">
        Alexa Fans
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: 'red'
  }
}));

export default function LoginPage(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('')
  const login = useLogin();
  const submit = (e) => {
      e.preventDefault();
      setLoading(true);
      login({ username: email, password }, '/dashboard')
      .then(() => {
        // props.history.push('/dashboard');
      })
      .catch((errorMessage) => {
          // console.log(errorMessage)
          setLoading(false)
          setErrorMessage('Invalid credentials')
       })
  };

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      props.history.push('/dashboard'); // User is signed in.
    } else {
      // No user is signed in.
    }
  });

  return (
    <Container component="main" maxWidth="xs" >
      <CssBaseline />
      <div className={classes.paper} >
        <a href="#home" style={{textDecoration: 'none'}}>
          <Typography component="h1" variant="h5" >
            Login - Alexa Fans
          </Typography>
        </a>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form onSubmit={submit} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <p className={classes.error}>{errorMessage}</p>

          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <ButtonComponent
            type="button"
            onClick={submit}
            disabled={loading}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </ButtonComponent>
          <Grid container>
            <Grid item xs>
              <ForgotPassword />
            </Grid>
            <Grid item xs>
              <Link variant='body2' onClick={()=>props.history.push('/register')} >
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
        {/*<SignInScreen /> //SOCIAL SIGNIN*/}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
