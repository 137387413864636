import React, {useState} from 'react';
import { useNotify } from 'react-admin';
import firebase from 'firebase';
import Avatar from '@material-ui/core/Avatar';
import { CircularProgress, Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { observer, inject } from 'mobx-react';


function ButtonComponent(props) {
  const { disabled } = props;
  return (
    <Button {...props} disabled={disabled}>
      {disabled && <CircularProgress size={14} />}
      {!disabled && 'Register'}
    </Button>
  );
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://AlexaBree.com">
        Alexa Fans
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: 'red'
  }
}));



function RegisterPage(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const notify = useNotify();

  function isFormValid() {
    if (email && confirmPassword && password) {
      if (password === confirmPassword) {
        return true;
      }
    } else {
      setErrorMessage('Form not valid')
      return false;
    }
  }

  const submit = async (e) => {
      e.preventDefault();
      setLoading(true);

      if (isFormValid()) {
        try {
          await props.ProfileStore.createNewUser({email, password});
          notify('Success!', "info")
        } catch(errorMsg) {
          setErrorMessage(errorMsg.message);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
  };

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      props.history.push('/dashboard'); // User is signed in.
    } else {
      // No user is signed in.
    }
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <a href="#home" style={{textDecoration: 'none'}}>
          <Typography component="h1" variant="h5" >
            Join - Alexa Fans
          </Typography>
        </a>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <form onSubmit={()=>submit} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            autoComplete="email"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            autoComplete="confirm-password"
          />

          <p className={classes.error}>{errorMessage}</p>

          <ButtonComponent
            type="button"
            onClick={submit}
            disabled={loading}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          />

          <Grid container style={{justifyContent: 'center'}}>
              <Link onClick={()=>props.history.push('/login')} variant="body2">
                {"Already have an account? Log in"}
              </Link>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default inject("DataStore", "FirebaseStore", "ProfileStore")(observer(RegisterPage))
