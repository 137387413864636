import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
}

const colors = {
  primary: {
    100: "#E5FCF1",
    200: "#27EF96",
    300: "#10DE82",
    400: "#0EBE6F",
    500: "#ee82ee",
    600: "#0A864F",
    700: "#086F42",
    800: "black",
    900: "#064C2E",
    901: "purple"
  },
};

const customTheme = extendTheme({ config , colors });

export default customTheme;
