import React from "react";

import LandingLayout from "../../components/layouts/LandingLayout";
import TermsPage from './TermsContent'

export default function Terms() {
  return (
    <LandingLayout>
      <TermsPage />
    </LandingLayout>
  );
}
