import React from "react";
import VideoView from './VideoView';
import { Stack, SimpleGrid} from "@chakra-ui/react";

export default function MoreVideos() {
  return (
    <SimpleGrid minChildWidth="15px" columns={2} spacing={4} m='3%'>
      <Stack direction={["column"]} spacing="8px">
        <VideoView title='Girlfriends Tight Vinyl Dress' videoSrc="https://www.youtube.com/embed/-hB8lG13cAU?controls=0&rel=0&disablekb=1&showinfo=0&wmode=transparent&showinfo=0" />
        <VideoView title='Red Cocktail Dress' videoSrc="https://www.youtube.com/embed/Vbuc-RjXS3o?controls=0&rel=0&disablekb=1&showinfo=0&wmode=transparent&showinfo=0" />
        <VideoView title='Handcuffed | Dressed as a nurse' videoSrc="https://www.youtube.com/embed/Ot6tO7Ar2ls?controls=0&rel=0&disablekb=1&showinfo=0&wmode=transparent&showinfo=0" />
      </Stack>
    </SimpleGrid>
  );
}
