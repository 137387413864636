import React, {Component} from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { FirebaseDataProvider, FirebaseAuthProvider } from "react-admin-firebase";
import FirebaseStore from './Stores/FirebaseStore';
import ProfileStore from './Stores/ProfileStore';
import DataStore from './Stores/DataStore';
import englishMessages from './i18n/en';
import { Provider as MobXProvider } from 'mobx-react';
import customRoutes from './routes';
import CustomLayout from './Layout/CustomLayout';
import LoginPage from './Pages/Login/LoginPage'
import PreLaunchDashboard from './Pages/Dashboard/PreLaunchDashboard'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';

const options = {
  logging: false,
  // rootRef: 'rootrefcollection/QQG2McwjR2Bohi9OwQzP',
  app: FirebaseStore.getFirebaseApp(),
  // watch: ['posts'];
  // dontwatch: ['comments'];
  persistence: 'local',
  // disableMeta: true
  dontAddIdFieldToDoc: true
}

const authProvider = FirebaseAuthProvider(FirebaseStore.getFirebaseConfig(), options);
const dataProvider = FirebaseDataProvider(FirebaseStore.getFirebaseConfig(), options);

const overideAuthProvider = {
  ...authProvider,
  checkAuth: async (params) => {
    let j = await authProvider.checkAuth()

    return authProvider.checkAuth();
  },
  getPermissions: async () => {
    let jk = await authProvider.getPermissions()

    return authProvider.getPermissions();
  },
  logout: async () => {
    await ProfileStore.logout();

    return Promise.resolve();
  },
};

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }

    return englishMessages; // Always fallback on english
}, 'en');

class App extends Component {

  constructor(props) {
    super(props);
  }

  render(props) {
    return (
      <div>
        <ToastContainer />
        <MobXProvider FirebaseStore={FirebaseStore} DataStore={DataStore} ProfileStore={ProfileStore}>
          <Admin loginPage={LoginPage} layout={CustomLayout}  dataProvider={dataProvider} authProvider={overideAuthProvider} customRoutes={customRoutes} i18nProvider={i18nProvider}>

          {permissions => [
            <Resource name="Dashboard" list={PreLaunchDashboard}/>,
            // (permissions && permissions.isAdmin === true) ? <Resource name="editor" list={Editor} /> : console.log('Permissions' + JSON.stringify(permissions)),
          ]}
          </Admin>
        </MobXProvider>
      </div>
    )
  }

}

export default App;
