import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Flex,
  Image,
  Heading,
  Stack,
  Text
} from "@chakra-ui/react";
import VideoView from '../../Pages/Dashboard/VideoView'

export default function Hero({
  title,
  subtitle,
  image,
  ctaLink,
  ctaText,
  ...rest
}) {
  return (
    <Flex
      align="center"
      justify={{ base: "center", md: "space-around", xl: "space-between" }}
      direction={{ base: "column-reverse", md: "row" }}
      wrap="no-wrap"
      minH="70vh"
      px={8}
      mb={16}
      {...rest}
    >
      <Stack
        spacing={4}
        w={{ base: "80%", md: "40%" }}
        align={["center", "center", "center", "center"]}
      >
        {/*<Heading
          as="h1"
          size="xl"
          fontWeight="bold"
          color="primary.800"
          textAlign={["center", "center", "left", "left"]}
        >
          {title}
        </Heading>*/}
        <Image src={'/img/redlips_kiss.png'} boxSize="30%" shadow="sm" />
        <Heading
          as="h2"
          size="md"
          color="primary.800"
          opacity="0.8"
          fontWeight="normal"
          lineHeight={1.5}
          textAlign={["center", "center", "left", "left"]}
        >
          {subtitle}
        </Heading>
        <a target="_blank" href={ctaLink}>
          <Button
            colorScheme="primary"
            borderRadius="8px"
            py="4"
            px="8"
            lineHeight="1"
            size="lg"
          >
            {ctaText}
          </Button>
        </a>
        <Text
          fontSize="md"
          mt={2}
          textAlign="center"
          color="primary.901"
          opacity="0.6"
        >
          Instant access of never before seen footage PLUS loads more.
        </Text>
      </Stack>
      <Box w='80%' m={12}>
        {/* TODO: Make this change every X secs
        <Image src={image} size="100%" rounded="1rem" shadow="2xl" />*/}
        <VideoView title={title} videoSrc="https://www.youtube.com/embed/8aj3B595_Dw?controls=0&rel=0&disablekb=1&showinfo=0&wmode=transparent&showinfo=0" />
      </Box>
    </Flex>
  );
}

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string
};

Hero.defaultProps = {
  title: "React landing page with Chakra UI",
  subtitle:
    "This is the subheader section where you describe the basic benefits of your product",
  image: "https://source.unsplash.com/collection/404339/800x600",
  ctaText: "Create your account now",
  ctaLink: "/signup"
};
