import { observable, decorate } from 'mobx';
import {firebaseApp, firebaseConfig} from './Config/firebase-config';
import Firebase from 'firebase'

class FirebaseStore {
  // @observable
  firebaseApp = firebaseApp;

  getFirebaseApp() {
    return this.firebaseApp;
  }

  getFirebaseConfig() {
    return firebaseConfig;
  }

  getFirebaseTimestamp() {
    return Firebase.database.ServerValue.TIMESTAMP;
  }
}

decorate(FirebaseStore, {
    firebaseApp: observable
});
export default new FirebaseStore();
