import React from 'react';

const TermsPage = ({ ProfileStore }) => {

    return (
      <div >


        <section id="home" className="one" style={{color: 'black'}}>
          <h1 style={{marginTop: '1.2em'}}>TERMS AND CONDITIONS</h1>
          <p>
          Definitions
The following terms are used regularly throughout these Terms of Service and have a particular meaning:

1. ABN means Australian Business Number.

2. ACN means Australian Company Number.

3. Agreement means the agreement formed between the Users and the Company under, and on the terms of, this Terms of Service.

4. Business Day means a day (other than a Saturday, Sunday or public holiday) on which banks are open for general banking business in Australia.

5. Company means Alexa Media.

6. Confidential Information means any written or verbal information that:

1. Is about each party’s business or affairs;

2. Is about the conduct of each party under this Agreement and the during the term of this Agreement;

3. A party informs the other party that it considers it confidential and/or proprietary;

4. A party would reasonably consider to be confidential in the circumstances; and

5. Is personal information within the meaning of the Privacy Act.

but does not include information that a party can establish:

1. Was in the public domain at the time it was given to that party;

2. Became part of the public domain, without that party’s involvement in any way, after being given to the party;

3. Was in party's possession when it was given to the party, without having been acquired (directly or indirectly) from the disclosing party; or

4. Was received from another person who had the unrestricted legal right to disclose that information free from any confidentiality obligation.

7. Corporations Act means the Corporations Act 2001 (Cth).

8. Customer means a user of Alexa Media that books a service through Alexa Media.

9. Equipment & Materials means any equipment and materials necessary for a Alexa Media Service.

10. Intellectual Property means all copyright, patents, inventions, trade secrets, know-how, product formulations, designs, circuit layouts, databases, registered or unregistered trademarks, brand names, business names, domain names and other forms of intellectual property;

11. Location means the address of the Customer or other location where the Service Provider is to attend the Alexa Media service.

12. Privacy Act means thePrivacy Act 1989 (Cth).

13. Privacy Policy means the Company’s privacy policy as updated from time-to-time, which can be found at

14. Recipient Created Tax Invoice has the same meaning as inA New Tax System (Goods and Services Tax) Act 1999 (Cth).

15. Service Provider means a party that undertakes Alexa Media services for Customers, and has entered into a Service Provider Agreement with the Company. Including by not limited to, drivers, sorters, marketing service providers etc.

16. Service Provider Agreement means an agreement between the Company and a Service Provider setting out the terms on which the Service Provider may provide services to Customers via Alexa Media.

17. Terms of Service means the terms and conditions of using Alexa Media, as updated from time-to-time, which can be found at

18. Alexa Media Service means any service booked by a Customer via Alexa Media

19. Alexa Media Service Request means a request for a Alexa Media Service made by a Customer to the Company.

20. Alexa Media Service Fee means the price paid by the Customer for a Alexa Media Service, as published by the Company (within Alexa Media) and/or agreed between the Customer and the Company from time-to-time.

21. Alexa Media means:

1. Alexa Media digital platform accessible from www.Alexa Media; and/or

2. Any Alexa Media mobile application.

22. User means any Customer or Service Provider that uses Alexa Media.


Using Alexa Media
1. General

1. To use Alexa Media, the User must book a Service through an official Alexa Media platform.

2. The User agrees that all use of Alexa Media, and all Alexa Media Services booked through Alexa Media, are subject to these Terms of Service.

3. When a Service Provider accepts a Alexa Media Service from a Customer, that forms an independent agreement between the Service Provider and the Customer on the terms set out in this Agreement.

4. Anyone over the age of 18 may use Alexa Media to request and book Alexa Media Services in the areas in which Service Providers operate.

5. Only Service Providers with a current Service Provider Agreement with the Company may undertake Alexa Media Services via Alexa Media.

6. The Customer acknowledges that each Alexa Media Service is performed independently by the Service Provider, and that the Company does not provide any of the services in a Alexa Media Service directly to the Customer.

2. Features

1. Alexa Media enables Customers to:

1. Request and book Alexa Media Services with the help of Service Providers through an official Alexa Media platform;

2. Rate Service Providers and Alexa Media Services; and

3. Manage their Alexa Media account (including history of services and payments).

2. Alexa Media enables the Service Provider to:

1. Receive requests from Customers for Alexa Media Services;

2. Manage accepted Alexa Media Services;

3. Log work undertaken and confirm completion of Alexa Media Services;

5. Manage their Alexa Media account (including history of services and payments).

3. Making a Booking

1. Alexa Media Services:

1. All our Services are charged as a one-off and will not entail a subscription or any long term commitment from the Customer.

2. Charges: Within 24 hours of your Service, you will be charged your Service Fee as outlined on our Alexa Media platforms.

2. To book a Alexa Media Service, the Customer may use any official Alexa Media platform.

3. The Company does not guarantee that a Service Provider will accept any Alexa Media Service Request.

4. The Company does not guarantee that a Service Provider will reschedule a Alexa Media Service Request if the Customer does not follow directions as indicated in the service timeframe or the address is incorrectly inputted on a Alexa Media platform by the Customer.

5. Undertaking the Alexa Media Service

1. The Service Provider agrees to arrive at the Location in the correct time frame (being the time specified by the Customer).

2. The Service Provider will undertake the Alexa Media Service to a professional standard, for the duration and/or outcome booked in the Alexa Media Service. Where a Alexa Media Service is not completed to a satisfactory standard (as determined by the Company, acting reasonably) the Service Provider will rectify the issue at the earliest availability.

3. The Customer must ensure that it provides the Service Provider with a safe environment, whether in a domestic or commercial setting. Without limitation, the Customer agrees to safely restrain all animals, and ensure there are no dangerous environments (such as live exposed electricity), and there are no unannounced persons in the Location.

4. The Customer agrees that the Service Provider is not liable for minor wear and tear during a Alexa Media Service. The Service Provider shall not be liable for any breakage with a value of under $500 if such breakage was genuinely accidental and in the course of providing the Alexa Media Service. The Customer agrees that the Service Provider shall have the first right to rectify, repair or resupply services to remedy any damage or incomplete work.

5. Cancellations

1. The Customer may cancel any booking in Alexa Media no less than 24 hours prior (for example, a Friday booking must be cancelled by 6.00pm on Wednesday) without penalty or charge.

2. Cancellations made with less than 24 hours notice will incur the full Service fee to be charged to the Customer.

3. Where a Service Provider is unable to attend a Alexa Media Service, the Company shall use its best endeavours to find a replacement Service Provider, however if an alternate Service Provider cannot be found, the Company shall not be liable to the Customer (although no Alexa Media Service Fees will be charged).

6. Ratings

1. The Customer may use all Alexa Media platforms to rate and provide feedback on the Service Provider and each Alexa Media Service.

2. The Customer agrees to provide accurate information and to not unreasonably give a Service Provider or a Alexa Media Service a low rating.

3. If the Customer is unsatisfied with the standard of a Alexa Media Service, the Customer must first discuss the matter with Alexa Media, and Alexa Media shall endeavour to resolve the matter within 2 Business Days.

Fees, Payments and Refunds
1. The Customer shall pay the Company the Alexa Media Service Fee for a Alexa Media Service undertaken by a Service Provider. The Company will issue a receipt to the Customer for the Alexa Media Service Fee via Alexa Media and/or email. All payments for a Alexa Media Service must be made through Alexa Media (or at the Company’s discretion, directly to the Company).

2. Payment of the Alexa Media Service Fee will be charged to the Customer’s credit card the day following the Alexa Media Service.

3. Payment of Alexa Media Service Fees shall be made by the Company to Service Providers in accordance with the Service Provider Agreement.

4. Alexa Media will provide a refund or credit of some or all of the Alexa Media Service fee (as appropriate) where, by no fault of the Customer, the Alexa Media Service is not performed or cannot be satisfactorily completed. Otherwise, no other refunds are provided except where required under law.

Direct contracting between customers and service providers
1. Without the written consent of the Company, the Service Provider shall not directly contract to provide services to a Customer outside of the Platform (and thereby exclude the Company) within 12 months of the last day that the Service Provider attended a Alexa Media Service at the relevant Customer. Where such a direct relationship is formed without the consent of the Company, the Service Provider agrees to pay to the Company 30% of the Alexa Media Service Fees that would have applied had those services been undertaken as Alexa Media Services.

Relationship
1. The parties agree that the Service Provider is an independent contractor to the Customer. Each Service Provider and Customer is responsible to each other with respect to each Alexa Media Service. The parties acknowledge and agree that:

1. The Service Provider is not an employee or subcontractor of the Company, and the Service Provider does not provide any services to Customers on behalf of the Company;

2. The Company is only the Service Provider’s agent for the purpose of arranging Alexa Media Services and receiving payments from the Customer;

3. The Service Provider is not an employee of the Customer or the Company; and

4. The Company is not an agent of the Customer.

General conditions
1. Licence

1. By accepting the terms and conditions of this Agreement, the User is granted a limited, non-exclusive and revocable licence to access and use Alexa Media for the duration of this Agreement, in accordance with the terms and conditions of this Agreement.

2. The Company may issue the licence to the User on the further terms or limitations (including the number of users or volume of use or transactions) as it sees fit.

3. The Company may revoke or suspend the User’s licence(s) in its absolute discretion for any reason that it sees fit, including for breach of the terms and conditions in this Agreement by the User.

2. Modification of Terms

1. The terms of this Agreement may be updated by the Company from time-to-time.

2. Where the Company modifies the terms, it will provide the User with written notice, and the User will be required to accept the modified terms in order to continue using Alexa Media.

3. Software-as-a-Service

1. The User agrees and accepts that Alexa Media is:

1. Hosted by the Company and shall only be installed, accessed and maintained by the Company, accessed using the internet or other connection to the Company servers and is not available ‘locally’ from the User’s systems; and

2. Managed and supported exclusively by the Company from the Company servers and that no ‘back-end’ access to Alexa Media is available to the User unless expressly agreed in writing.

3. As a hosted and managed service, the Company reserves the right to upgrade, maintain, tune, backup, amend, add or remove features, redesign, improve or otherwise alter Alexa Media.

4. Support

1. The Company provides user support for Alexa Media via all of its official platforms and Contact email.

2. The Company shall endeavour to respond to all support requests within 2 Business Days.

5. Use & Availability

1. The User agrees that it shall only use Alexa Media for legal purposes and shall not use it to engage any conduct that is unlawful, immoral, threatening, abusive or in a way that is deemed unreasonable by the Company in its discretion.

2. The User agrees that the Company shall provide access to Alexa Media to the best of its abilities, however:

1. Access to Alexa Media may be prevented by issues outside of its control; and

2. It accepts no responsibility for ongoing access to Alexa Media.

6. Privacy

1. The Company maintains the Privacy Policy in compliance with the provisions of the Privacy Act for data that it collects about the User and other customers.

2. The Privacy Policy does not apply to how a Service Provider handles personal information. If necessary under the Privacy Act, it is the Service Provider’s responsibility to meet the obligations of the Privacy Act by implementing a privacy policy in accordance with law.

3. Alexa Media may use cookies to improve a User’s experience while browsing, while also sending browsing information back to the Company. The User may manage how it handles cookies in its own browser settings.

7. Data

1. Security. The Company takes the security of Alexa Media and the privacy of its Users very seriously. The Client agrees that the Client shall not do anything to prejudice the security or privacy of the Company’s systems or the information on them.

2. Transmission. The Company shall do all things reasonable to ensure that the transmission of data occurs according to accepted industry standards. It is up to the Client to ensure that any transmission standards meet the Client’s operating and legal requirements.

3. Storage. Data that is stored by the Company shall be stored according to accepted industry standards.

4. Backup. The Company shall perform backups of its entire systems in as reasonable manner at such times and intervals as is reasonable for its business purposes. The Company does not warrant that it is able to backup or recover specific Client Data from any period of time unless so stated in writing by the Company.

8. Intellectual Property

1. Trademarks. The Company has moral & registered rights in its trade marks and the User shall not copy, alter, use or otherwise deal in the marks without the prior written consent of the Company.

2. Proprietary Information. The Company may use software and other proprietary systems and Intellectual Property for which the Company has appropriate authority to use, and the User agrees that such is protected by copyright, trademarks, patents, proprietary rights and other laws, both domestically and internationally. The User warrants that it shall not infringe on any third-party rights through the use of Alexa Media.

3. The Alexa Media Application. The User agrees and accepts that Alexa Media is the Intellectual Property of the Company and the User further warrants that by using Alexa Media the User will not:

1. Copy Alexa Media or the services that it provides for the User’s own commercial purposes; and

2. Directly or indirectly copy, recreate, decompile, reverse engineer or otherwise obtain, modify or use any source or object code, architecture, algorithms contained in Alexa Media or any documentation associated with it.

4. Content. All content submitted to the Company, whether via Alexa Media or directly by other means, becomes and remains the Intellectual Property of the Company, including (without limitation) any source code, analytics, insights, ideas, enhancements, feature requests, suggestions or other information provided by the User or any other party with respect to Alexa Media.

9. Disclaimer of Third Party Services & Information

1. The User acknowledges that Alexa Media Is dependent on third-party services, including but not limited to:

1. Banks, credit card providers and merchant gateway providers;

2. Telecommunications services;

3. Hosting services;

4. Email services; and

5. Analytics services.

2. The User agrees that the Company shall not be responsible or liable in any way for:

1. Interruptions to the availability of Alexa Media due to third-party services; or

2. Information contained on any linked third party website.

10. Liability & Indemnity

1. The User agrees that it uses Alexa Media at its own risk.

2. The User acknowledges that Alexa Media does not make Alexa Media Services on its own behalf.

3. The User acknowledges that the Company is not responsible for the conduct or activities of any Service Provider and that the Company is not liable for such under any circumstances.

4. The User agrees to indemnify the Company for any loss, damage, cost or expense that the Company may suffer or incur as a result of or in connection with the User’s use of or conduct in connection with Alexa Media, including any breach by the User of these Terms.

5. In no circumstances will the Company be liable for any direct, incidental, consequential or indirect damages, personal injury, death, damage to property, loss of property, loss or corruption of data, loss of profits, goodwill, bargain or opportunity, loss of anticipated savings or any other similar or analogous loss resulting from the User’s access to, or use of, or inability to use Alexa Media, whether based on warranty, contract, tort, negligence, in equity or any other legal theory, and whether or not the Company knew or should have known of the possibility of such damage, loss, personal injury or death, or business interruption of any type, whether in tort, contract or otherwise.

6. Certain rights and remedies may be available under the Competition and Consumer Act 2010 (Cth) or similar legislation of other States or Territories and may not be permitted to be excluded, restricted or modified. Apart from those that cannot be excluded, the Company and the Company’s related entities exclude all conditions and warranties that may be implied by law. To the extent permitted by law, the Company’s liability for breach of any implied warranty or condition that cannot be excluded is restricted, at the Company’s option to:

1. The re-supply of services or payment of the cost of re-supply of services; or

2. The replacement or repair of goods or payment of the cost of replacement or repair.

11. Termination

1. Either party may terminate this Agreement by giving the other party written notice.

2. Termination of this agreement is without prejudice to and does not affect the accrued rights or remedies of any of the parties arising in any way out of this agreement up to the date of expiry or termination.

3. Termination does not affect any of the rights accrued by a party prior to termination, and he rights and obligations under clauses 6.8, 6.11 and 6.12 survive termination of this Agreement.

12. Dispute Resolution

1. If any dispute arises between the parties in connection with this Agreement (Dispute), then either party may notify the other of the Dispute with a notice (Dispute Notice) which:

1. Includes or is accompanied by full and detailed particulars of the Dispute; and

2. Is delivered within 10 Business Days of the circumstances giving rise to the Dispute first occurring.

2. Within 10 Business Days after a Dispute Notice is given, a representative (with the authority to resolve the dispute) parties must meet (virtually or otherwise) and seek to resolve the Dispute.

3. Subject to clause (d), a party must not bring court proceedings in respect of any Dispute unless it first complies with the requirements of the dispute resolution mechanism outlined in this clause.

4. Nothing in this clause prevents either party from instituting court proceedings to seek urgent injunctive, interlocutory or declaratory relief in respect of a Dispute.

5. Despite the existence of a Dispute, the parties must continue to perform their respective obligations under this document and any related agreements.

13. Electronic Communication, Amendment & Assignment

1. The words in this clause that are defined in the Electronic Transactions Act 1999 (Cth) have the same meaning.

2. The User can direct notices, enquiries, complaints and so forth to the Company as set out in this Agreement. The Company will notify the User of a change of details from time-to-time.

3. The Company will send the User notices and other correspondence to the details that the User submits to the Company, or that the User notifies the Company of from time-to-time. It is the User’s responsibility to update its contact details as they change.

4. A consent, notice or communication under this Agreement is effective if it is sent as an electronic communication unless required to be physically delivered under law.

5. Notices must be sent to the parties’ most recent known contact details.

6. The User may not assign or otherwise create an interest in this Agreement.

7. The Company may assign or otherwise create an interest in its rights under this Agreement by giving written notice to the User.

14. General

1. Special Conditions. The parties may agree to any Special Conditions to this Agreement in writing.

2. Prevalence. To the extent this Agreement is in conflict with, or inconsistent with, the terms of a Service Provider Agreement, or any Special Conditions made under this Agreement, as relevant, the terms of those other agreements or Special Conditions shall prevail.

3. Disclaimer. Each party acknowledges that it has not relied on any representation, warranty or statement made by any other party, other than as set out in this Agreement.

4. Relationship. The relationship of the parties to this Agreement does not form a joint venture or partnership.

5. Waiver. No clause of this Agreement will be deemed waived and no breach excused unless such waiver or consent is provided in writing.

6. Further Assurances. Each party must do anything necessary (including executing agreements and documents) to give full effect to this Agreement and the transaction facilitated by it.

7. Governing Law. This Agreement is governed by the laws of New South Wales, Australia. Each of the parties hereby submits to the non-exclusive jurisdiction of courts with jurisdiction there.

8. Severability. Any clause of this Agreement, which is invalid or unenforceable, is ineffective to the extent of the invalidity or unenforceability without affecting the remaining clauses of this Agreement.
          </p>

        </section>
      </div>
    );
};

export default TermsPage
