import React from 'react';
import { Heading, Stack } from "@chakra-ui/react";

const VideoView = (props) => {

  const {title, videoSrc} = props;

  return (
    <Stack direction={["column"]} spacing="8px">
      <Heading align='center' as="h1" size="xl" fontWeight="bold" color="primary.800">{title}</Heading>
      <div className="wrapper">
        <div className="frame-container">
          <iframe
            title="Welcome"
            src={videoSrc}
            allowFullScreen
          />
        </div>
      </div>
    </Stack>
  )

}

export default VideoView
