import React from "react";
import { Heading, Stack, SimpleGrid, Box, Image } from "@chakra-ui/react";

export default function Photos() {
  return (
    <SimpleGrid minChildWidth="15px" columns={2} spacing={4} m='3%'>
      <Stack direction={["column"]} spacing="8px">
        <Box>
          <Heading>Trying some glue on nails</Heading>
          <Image alt='Gule on nails' src="/img/Photos/glueonnails.png" />
        </Box>
        <Box>
          <Heading>Leather mini dress</Heading>
          <Image alt='Mini leather dress' src="/img/Photos/leatherdress1.png" />
        </Box>
        <Box>
          <Heading>Love stockings & heels!!</Heading>
          <Image alt='Stockings with heels' src="/img/Photos/stockingslegs.png" />
        </Box>
      </Stack>
    </SimpleGrid>
  );
}
